import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Platform } from 'react-native'
import * as Sentry from 'sentry-expo'
import { ROYALTY_LOGGING_URL } from '../../Helpers/variables'
import useAudio from '../../Hooks/useAudio'
import useTokens from '../../Hooks/useTokens'

export const SoundContext = React.createContext()

export const SoundProvider = ({ children }) => {
	const { sound, Audio } = useAudio()
	const [oldTrackList, setOldTrackList] = useState([])
	const [trackList, setTrackList] = useState([])
	const [currentPlayingTrack, setCurrentPlayingTrack] = useState(null)
	const [currentPlayingTrackIndex, setCurrentPlayingTrackIndex] = useState(0)
	const [isShuffleActive, setIsShuffleActive] = useState(false)
	const [repeatType, setRepeatType] = useState('none')
	const [repeatOnce, setRepeatOnce] = useState(false)
	const [volume, setVolume] = useState(100)

	const [mediaPlayerAcquisition, setMediaPlayerAcquisition] = useState({
		isShuffleBtnVisible: true,
		isRepeatBtnVisible: true
	})

	const { getTokens } = useTokens()
	let [isRoyaltyLogSent, setIsRoyaltyLogSent] = useState(false)
	const postRoyaltyLogging = async id => {
		if (isRoyaltyLogSent) return

		isRoyaltyLogSent = true
		setIsRoyaltyLogSent(true)

		const { access } = await getTokens()
		axios
			.post(
				ROYALTY_LOGGING_URL + id,
				{},
				{
					headers: { Authorization: `JWT ${access}` }
				}
			)
			.then(response => {
				if (response.status === 201) console.log('Success royalty logging...')
				else console.log('Failed royalty logging...')
			})
	}

	/*
	 * function handleTrackPlayer()
	 * @description: handles the track player
	 *
	 * Checks and change tracks according to the current playing track status
	 *
	 * @param {object} track
	 */
	const handleTrackPlayer = async (track, tracksList = trackList, trackIndex = currentPlayingTrackIndex) => {
		if (track === undefined && tracksList.length > 0) {
			track = tracksList[0]
		}
		
		if (track.track_file === null) return

		setTrackList(tracksList)
		setCurrentPlayingTrackIndex(trackIndex)
		setCurrentPlayingTrack(track)

		let result = await sound.current.getStatusAsync()

		/*
		 * Check if there is a song playing or loaded
		 * if not, load a song and play it for the very first time
		 */
		if (!result.isLoaded) {
			setCurrentPlayingTrack(track)
			try {
				await sound.current.loadAsync({ uri: track.track_file })
				await sound.current.playAsync()

				setIsRoyaltyLogSent(false)
				postRoyaltyLogging(track.id)
				handleChangeVolume(volume)
				return true
				// /*
				//  * Check if the timer is checked and the timer is not yet on countdown
				//  * if all true, call handle timer change function
				//  */
				// if (timerProperties.isTimerChecked && !timerProperties.isTimerOnCountdown) {
				// 	await handleTimerChange(timerProperties.isTimerChecked)
				// }
			} catch (error) {
				console.log(error)
				if (Platform.OS === 'web') Sentry.Browser.captureException(error)
				else Sentry.Native.captureException(error)
			}

			return true
		}

		/*
		 * Check if there is a song playing or loaded
		 * if there is a song playing, check the current track id,
		 * if track ids matches, pause the current playing track
		 */
		if (result.isLoaded && result.isPlaying && currentPlayingTrack.id === track.id) {
			try {
				await sound.current.pauseAsync()
				handleChangeVolume(volume)
			} catch (error) {
				if (Platform.OS === 'web') Sentry.Browser.captureException(error)
				else Sentry.Native.captureException(error)
			}

			return true
		}

		/*
		 * Same as above function but to resume the current playing track
		 */
		if (result.isLoaded && !result.isPlaying && currentPlayingTrack.id === track.id) {
			try {
				await sound.current.playAsync()
				handleChangeVolume(volume)
			} catch (error) {
				if (Platform.OS === 'web') Sentry.Browser.captureException(error)
				else Sentry.Native.captureException(error)
			}
			return true
		}

		/*
		 * Check if there is a song playing or loaded
		 * if a song is playing or loaded,
		 * check for the passed track and current playing track ids,
		 * if the ids are different, unload the current track and
		 * start playing the newly passed in track
		 */
		if (result.isLoaded && currentPlayingTrack.id !== track.id) {
			setCurrentPlayingTrack(track)

			try {
				await sound.current.unloadAsync()
				await sound.current.loadAsync({ uri: track.track_file })
				await sound.current.playAsync()
				setIsRoyaltyLogSent(false)
				postRoyaltyLogging(track.id)
				handleChangeVolume(volume)
			} catch (error) {
				if (Platform.OS === 'web') Sentry.Browser.captureException(error)
				else Sentry.Native.captureException(error)
			}

			return true
		}
	}

	/*
	 * function handleSeek()
	 *
	 * @description: Handle track position change
	 * if the track is playing, update the position
	 * according to the passed in position value from the track player component
	 *
	 * @param position {number} - milliseconds
	 */
	const handleSeek = async position => {
		try {
			await sound.current.setPositionAsync(position)
		} catch (error) {
			if (Platform.OS === 'web') Sentry.Browser.captureException(error)
			else Sentry.Native.captureException(error)
		}
	}

	/*
	 * function handleTrackPlayer()
	 *
	 * @description: Handles the track player next/previous change
	 * checks if the current track is the last track in the list
	 * if it is, play the first track in the list
	 * if not, play the next track in the list
	 *
	 * @param direction {string} - next or previous
	 */
	const handleTrackChange = async direction => {
		let index,
			currentIndex = currentPlayingTrackIndex
		if (!isShuffleActive) {
			if (direction === 'next') {
				if (repeatType === 'one' && repeatOnce) {
					index = currentIndex
				} else if (repeatType === 'all' || repeatType === 'repeatAllTracks')
					index = currentIndex + 1 >= trackList.length ? 0 : currentIndex + 1
				else {
					//repeat type is none, if end track, pause the song
					if (currentIndex + 1 >= trackList.length) {
						index = 0
						// await sound.current.unloadAsync()
						// return true
					} else index = currentIndex + 1
				}
			} else index = currentIndex === 0 ? trackList.length - 1 : currentIndex - 1
		} else {
			//shuffle is active
			let shuffleListIndex, newShuffleIndex, shuffleListNewTrackIdToPlay, shuffleListNewIdToPlay
			if (direction === 'next') {
				if (repeatType === 'one') index = currentIndex
				else if (repeatType === 'all') {
					shuffleListIndex = trackList.findIndex(x => x.id === trackList[currentPlayingTrackIndex].id)
					newShuffleIndex = shuffleListIndex + 1
					newShuffleIndex = newShuffleIndex > trackList.length ? 0 : newShuffleIndex
					shuffleListNewTrackIdToPlay = trackList[newShuffleIndex].track.id
					shuffleListNewIdToPlay = trackList[newShuffleIndex].id
					index = trackList.findIndex(x => x.id === shuffleListNewIdToPlay)
				} else {
					//repeat type is none, if end track, pause the song
					shuffleListIndex = trackList.findIndex(x => x.id === trackList[currentPlayingTrackIndex].id)
					newShuffleIndex = shuffleListIndex + 1

					if (newShuffleIndex >= trackList.length) {
						newShuffleIndex = 0
					}

					shuffleListNewTrackIdToPlay = trackList[newShuffleIndex].track.id
					shuffleListNewIdToPlay = trackList[newShuffleIndex].id
					index = trackList.findIndex(x => x.id === shuffleListNewIdToPlay)
				}
			} else {
				shuffleListIndex = trackList.findIndex(x => x.id === trackList[currentPlayingTrackIndex].id)
				newShuffleIndex = shuffleListIndex === 0 ? trackList.length - 1 : shuffleListIndex - 1
				shuffleListNewTrackIdToPlay = trackList[newShuffleIndex].track.id
				shuffleListNewIdToPlay = trackList[newShuffleIndex].id
				index = newShuffleIndex
			}
		}

		try {
			handleChangeVolume(volume)
			await sound.current.unloadAsync()
			await handleTrackPlayer(trackList[index].track, trackList, index)
			setIsRoyaltyLogSent(false)
			// postRoyaltyLogging()
		} catch (error) {
			if (Platform.OS === 'web') Sentry.Browser.captureException(error)
			else Sentry.Native.captureException(error)
		}
	}

	/*
	 * function handleClickShuffle()
	 *
	 * @description: Determines if shuffle or unshuffle of tracks list
	 */
	const handleClickShuffle = () => {
		if (trackList.length > 0) {
			if (!isShuffleActive) {
				shuffle()
			} else {
				let currentUniqueId = trackList[currentPlayingTrackIndex].id
				let currentTrackIndex =
					oldTrackList.length > 0
						? oldTrackList.findIndex(x => x.id === currentUniqueId)
						: trackList.findIndex(x => x.id === currentUniqueId)
				setCurrentPlayingTrackIndex(currentTrackIndex)
				setTrackList(oldTrackList)
				setOldTrackList([])
			}
			setIsShuffleActive(!isShuffleActive)
		}
	}

	/*
	 * function generateRandomIndex()
	 *
	 * @description: Generate random integer from 0 to total length of track list
	 */
	const generateRandomIndex = () => {
		let randomNum = Math.floor(Math.random() * trackList.length + 1)
		randomNum = randomNum === trackList.length ? randomNum - 1 : randomNum
		return randomNum
	}

	/*
	 * function shuffle()
	 *
	 * @description: Handles the shuffling of tracks list
	 */
	const shuffle = () => {
		let currentIndex = trackList.length,
			temporaryValue,
			randomIndex
		// let trackListIndexShuffleTemp = []
		let trackListTemp = []

		// While there remain elements to shuffle...
		while (0 !== currentIndex) {
			// Pick a remaining element...
			randomIndex = generateRandomIndex()

			// if (currentPlayingTrack && trackListIndexShuffleTemp.length === 0) {
			// 	trackListIndexShuffleTemp.push({
			// 		trackId: trackList[0].track.id,
			// 		id: trackList[0].id
			// 	})
			// 	trackListTemp.push(trackList[0])
			// 	currentIndex -= 1
			// } else
			if (currentIndex === 1) {
				//Find values that are in trackList but not in trackListIndexShuffleTemp
				let result = trackList.filter(x => x.id === trackList[currentPlayingTrackIndex].id)
				trackListTemp = trackListTemp.filter(x => x.id !== trackList[currentPlayingTrackIndex].id)
				trackListTemp.unshift(result[0])
				currentIndex -= 1
			} else {
				let checkIfExist = trackListTemp.filter(x => x.id === trackList[randomIndex].id)
				if (checkIfExist.length === 0) {
					currentIndex -= 1
					trackListTemp.push(trackList[randomIndex])
				}
			}
		}
		if (trackListTemp.length > 0) {
			setOldTrackList(trackList)
			setTrackList(trackListTemp)
			setCurrentPlayingTrackIndex(0)
		}
	}

	/*
	 * function handleClickRepeat()
	 *
	 * @description: Handles the behavior of repeat type(repeat all, repeat one)
	 */
	const handleClickRepeat = repeatType => {
		setRepeatType(repeatType)
		setRepeatOnce(repeatType === 'one' ? true : false)
	}

	/*
	 * function handleChangeVolume()
	 *
	 * @description: Handles the volume change
	 */
	const handleChangeVolume = async volume => {
		let newVolume = volume > 0 ? volume / 100 : volume
		try {
			await sound.current.setVolumeAsync(newVolume)
			setVolume(volume)
		} catch (error) {
			if (Platform.OS === 'web') Sentry.Browser.captureException(error)
			else Sentry.Native.captureException(error)
		}
	}

	useEffect(() => {
		async function boot() {
			await Audio.setAudioModeAsync({
				allowsRecordingIOS: false,
				staysActiveInBackground: true,
				interruptionModeIOS: 2,
				playsInSilentModeIOS: true,
				shouldDuckAndroid: true,
				playThroughEarpieceAndroid: false
			})
		}
		boot()
	}, [])

	return (
		<SoundContext.Provider
			value={{
				sound,
				trackList,
				setTrackList,
				currentPlayingTrack,
				setCurrentPlayingTrack,
				handleTrackPlayer,
				handleTrackChange,
				handleSeek,
				handleClickShuffle,
				isShuffleActive,
				handleClickRepeat,
				repeatType,
				mediaPlayerAcquisition,
				setMediaPlayerAcquisition,
				volume,
				handleChangeVolume,
				currentPlayingTrackIndex,
				setIsShuffleActive,
				setCurrentPlayingTrackIndex
			}}
		>
			{children}
		</SoundContext.Provider>
	)
}
