import { Ionicons } from '@expo/vector-icons'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { ScrollView, Text, View, useWindowDimensions } from 'react-native'
import { Menu, MenuItem } from 'react-native-material-menu'
import { PLAYLIST_TRACKS_URL, PLAYLIST_TRACKS_EDIT_URL } from '../../../Helpers/variables'
import useTokens from '../../../Hooks/useTokens'
import { trackStyles } from './TrackStyles'

export const MoveTrackPlaylist = ({
	track,
	setMenuVisible,
	userPlaylists,
	openNotifModal,
	position = 0,
    tracksList,
    setTrackList,
	playlistTrackId,
	playlistId
}) => {
	const { width, height } = useWindowDimensions();
	const [isWideScreen, setIsWideScreen] = useState(width >= 700)
	const [isSmallScreen, setIsSmallScreen] = useState(width <= 320)
	const [otherPlaylists, setOtherPlaylists] = useState([])
	const handleResize = () => {
		setIsWideScreen(width >= 700)
		setIsSmallScreen(width <= 320)
	}
	
	useEffect(() => {
		removeSourcePlaylist()
	}, [])

	useEffect(() => {
		handleResize()
	}, [width])

    const moveTrackLabel = 'Flyt til en anden playliste'

	const [playlistVisible, setPlaylistVisible] = useState(false)
	const [modalVisible, setModalVisible] = useState(false)
	const { getTokens } = useTokens()

	const removeSourcePlaylist = () => {
		let newPlaylists = []
		userPlaylists.map(playlist => {
			if (playlist.id !== playlistId) {
				newPlaylists.push(playlist)
			}
		})
		setOtherPlaylists(newPlaylists)
	}

	const moveToplaylist = async (playlistId, track) => {
		const { access } = await getTokens()
		axios
			.post(
				PLAYLIST_TRACKS_URL + playlistId,
				{ track, position },
				{
					headers: { Authorization: `JWT ${access}` }
				}
			)
			.then(async response => {
				if (response.status === 201) {
                    await removeFromPlaylist(playlistTrackId)
				} else {
					openNotifModal(moveTrackLabel, 'Kunne ikke flytte', true)
				}
			})
			.catch(err => {
				console.log('moveToplaylist: ' + err)
				if (err.message === 'Request failed with status code 400') {
					openNotifModal(moveTrackLabel, 'Nummeret findes allerede i spilleliste', true)
				}
			})
	}

    const removeFromPlaylist = async (playlistTrackId) => {
		const { access } = await getTokens()
        axios
            .delete(PLAYLIST_TRACKS_EDIT_URL + playlistTrackId,
                {
					headers: { Authorization: `JWT ${access}` }
				}
            )
            .then(response => {
                if (response.status === 204) {
                    let tracksListTemp = tracksList
                    tracksListTemp = tracksListTemp.filter(x => x.id !== playlistTrackId)
                    setTrackList(tracksListTemp, true)
					openNotifModal(moveTrackLabel, 'Flyttet', true)
                }
            })
            .catch(err => {
            })
            .finally(() => {
            })
    }

	const handlePlaylistMenuClosing = () => {
		if (!modalVisible) {
			setPlaylistVisible(false)
		}
	}

	return (
		<View key={'moveplaylistviewkey' + track.id}>
			<Menu
				style={trackStyles.menuView}
				key={'moveplaylist' + track.id}
				visible={playlistVisible}
				anchor={
					<MenuItem
						onPress={() => {
							setPlaylistVisible(true)
						}}
					>
						<Text style={trackStyles.menuTextView}>{moveTrackLabel}</Text>
					</MenuItem>
				}
				onRequestClose={() => handlePlaylistMenuClosing() }
			>
				<View>
					<Ionicons name="arrow-back" size={25} color={'white'} style={{ marginLeft: 15 }} />
				</View>
				<ScrollView style={{ maxHeight: 200 }}>
					{otherPlaylists.map(playlist => (
						<MenuItem
							key={'moveplaylistkey' + playlist.id}
							onPress={async () => {
                                await moveToplaylist(playlist.id, track.id)
                                setPlaylistVisible(false)
                                setMenuVisible(false)
							}}
						>
							<Text style={trackStyles.menuTextView}>{playlist.name}</Text>
						</MenuItem>
					))}
				</ScrollView>
			</Menu>
		</View>
	)
}
