import { Platform } from 'react-native'

export const colors = {
	primary: '#151a28',
	secondary: '#1E273D',
	accent: '#ff6e00',
	boxes: '#1c212e',
	sideBar: '#171c29'
}

export const SENTRY_DSN = 'https://2ad40b193c3d441bbecfa28c0fc6ecdb@o1228491.ingest.sentry.io/6374247'

export const URL = 'https://www.musicminduat.dk'
// export const URL = 'http://localhost:8000'
// export const URL = 'http://192.168.196.50:8000'
export const CORS_URL = ''
export const BASE_API_URL = Platform.OS === 'web' ? CORS_URL + URL : URL

export const LOGIN_URL = BASE_API_URL + '/api/token/obtain/'
export const REFRESH_URL = BASE_API_URL + '/api/token/refresh/'

export const MAIN_CONTAINER_URL = BASE_API_URL + '/music/ContainerGroups/'

export const PLAYLIST_GROUP_URL = BASE_API_URL + '/music/PlaylistGroups/'
export const PLAYLIST_TRACKS_URL = BASE_API_URL + '/music/musicmind-playlist/tracks/'

export const CREATE_PLAYLIST_URL = BASE_API_URL + '/music/playlist'
export const PLAYLIST_URL = BASE_API_URL + '/music/playlist'
export const PLAYLIST_ALL_URL = BASE_API_URL + '/music/playlists/all'
export const USER_PLAYLIST_URL = BASE_API_URL + '/music/playlist'

export const TIMER_ACQUISITION_URL = BASE_API_URL + '/music/timer-acquisition/'
export const MEDIAPLAYER_ACQUISITION_URL = BASE_API_URL + '/music/mediaplayer-acquisition/'

export const INTERNAL_CONTAINER_URL = BASE_API_URL + '/music/InternalContainerGroups/'
export const INTERNAL_URL = BASE_API_URL + '/music/Internal/'
export const INTERNAL_PLAYLIST_GROUP_URL = BASE_API_URL + '/music/InternalPlaylistGroups/'

export const SEARCH_URL = BASE_API_URL + '/music/musicmindsearch/'
export const SEARCH_TRACKS_URL = BASE_API_URL + '/music/musicmindsearch/tracks/'
export const SEARCH_ALBUMS_URL = BASE_API_URL + '/music/musicmindsearch/albums/'
export const SEARCH_ARTISTS_URL = BASE_API_URL + '/music/musicmindsearch/artists/'
export const SEARCH_GENRE_URL = BASE_API_URL + '/music/musicmindsearch/genre/'
export const SEARCH_YEAR_URL = BASE_API_URL + '/music/musicmindsearch/year/'
export const SEARCH_PLAYLISTS_URL = BASE_API_URL + '/music/musicmindsearch/playlists/'
export const SEARCH_CONTAINERS_URL = BASE_API_URL + '/music/musicmindsearch/containers/'
export const SEARCH_ACCOMPANIMENT_URL = BASE_API_URL + '/music/musicmindsearch/accompaniment/'
export const SEARCH_COMPOSER_URL = BASE_API_URL + '/music/musicmindsearch/composer/'

export const ARTIST_TRACKS_URL = BASE_API_URL + '/music/musicmind-artist/'
export const ALBUM_TRACKS_URL = BASE_API_URL + '/music/musicmind-album/'
export const GENRE_TRACKS_URL = BASE_API_URL + '/music/genre/tracks/'
export const YEAR_TRACKS_URL = BASE_API_URL + '/music/year/tracks/'
export const COMPOSER_TRACKS_URL = BASE_API_URL + '/music/composer/tracks/'

export const PLAYLIST_TRACKS_EDIT_URL = BASE_API_URL + '/music/musicmind-playlist/tracks/edit/'

export const COPYPLAYLIST_ASSIGNED_URL = BASE_API_URL + '/music/copy-playlist-assigned/'
export const REVERSEUSERLOOKUP_URL = BASE_API_URL + '/music/reverse-userlookup/'
export const ASSIGNED_URL = BASE_API_URL + '/music/assigned/'

export const USERTYPE_URL = BASE_API_URL + '/api/user-type/'
export const USERNAME_URL = BASE_API_URL + '/api/user-name/'

export const CHANGEPASS_URL = BASE_API_URL + '/api/change_password/'
export const CREATEUSER_URL = BASE_API_URL + '/api/user/create/'
export const USERLIST_URL = BASE_API_URL + '/api/user-list/'
export const USERLISTSOONTOEXPIRE_URL = BASE_API_URL + '/api/user-soon-expire/'
export const USERLISTDEACTIVATED_URL = BASE_API_URL + '/api/user-deaktivated/'
export const ROYALTY_LOGGING_URL = BASE_API_URL + '/music/Logging/'
export const IMAGES = BASE_API_URL + '/images/'
export const SONGREQUEST = BASE_API_URL + '/music/songrequest/'
export const NEWS_FEED_URL = BASE_API_URL + '/music/news/'
export const FEEDBACKREQUEST_URL = BASE_API_URL + '/music/feedbackrequest/'
export const NEWS_ENGAGEMENT_URL = BASE_API_URL + '/music/news-engagement/'

export const SHOULD_APP_WORK = BASE_API_URL + '/music/android-should-work/'

export const MUSICTHERAPISTS_URL = BASE_API_URL + '/music/musictherapists/'

export const USER_DEVICE_URL = BASE_API_URL + '/music/user-device/'
export const TERMS_CONDITIONS_URL = BASE_API_URL + '/music/terms-conditions/'
export const MM_YEAR_URL = BASE_API_URL + '/music/musicmind/year'
export const MM_GENRE_URL = BASE_API_URL + '/music/musicmind/genre'

export const MAIN_CONTAINER_CATEGORY_BOXES_URL = BASE_API_URL + '/music/container-category-boxes/'
export const MAIN_CONTAINER_CATEGORY_BOX_SUBS_URL = BASE_API_URL + '/music/container-category-box-subs/'
export const SUB_CONTAINER_BOXES_URL = BASE_API_URL + '/music/sub-container-boxes/'
export const SUB_CONTAINER_PLAYLIST_GROUP_URL = BASE_API_URL + '/music/sub-container-playlist-groups/'

export const WHATS_NEW_URL = BASE_API_URL + '/music/whats-new/'
export const TRACKS_CHECKER_URL = BASE_API_URL + '/music/existing-track-checker?artist_name={singer}&song_title={title}'
export const TOP_TRACKS_URL = BASE_API_URL + '/music/top-tracks/'
export const BULK_SONG_REQUEST = BASE_API_URL + '/music/bulk-song-requests/'
export const BULK_SONG_REQUEST_ALL = BASE_API_URL + '/music/bulk-song-requests/all'
export const BULK_REQUESTED_SONGS = BASE_API_URL + '/music/bulk-requested-songs/'
export const TAG_AS_DONE = BASE_API_URL + '/music/song-requests/'
export const MM_ARTISTS_URL = BASE_API_URL + '/music/musicmind/artists'
export const MM_ALBUMS_URL = BASE_API_URL + '/music/musicmind/albums'
export const ANNOUNCEMENTS_URL = BASE_API_URL + '/music/announcements/'
export const NEW_ITEM_LABEL_URL = BASE_API_URL + '/music/new-item-label-visibility/'
export const THIRD_PARTY_INFO_TEXT = BASE_API_URL + '/music/import-tracks-third-party-info-text/genInfo'