import React from 'react'
import { MenuItem } from 'react-native-material-menu'
import { View, Text, Alert } from 'react-native'
import { PLAYLIST_TRACKS_EDIT_URL } from '../../../Helpers/variables'
import useAxios from '../../../Hooks/useAxios'
import { ConfirmationModal } from '../../../Components/Modals/ConfirmationModal'
import { trackStyles } from './TrackStyles'

export const RemoveTrackPlaylist = ({ playlistTrackId, tracksList, setTrackList }) => {
	const api = useAxios()
	const removeTrackLabel = 'Fjern fra spillelisten'
	const [removePlaylistConfirmModal, setRemovePlaylistConfirmModal] = React.useState(false)

	const cancelCallbackRemovePlaylist = () => {
		setRemovePlaylistConfirmModal(false)
	}

	const removeFromPlaylist = () => {
		api
			.delete(PLAYLIST_TRACKS_EDIT_URL + playlistTrackId)
			.then(response => {
				if (response.status === 204) {
					//refresh trackslist
					let tracksListTemp = tracksList
					tracksListTemp = tracksListTemp.filter(x => x.id !== playlistTrackId)
					setTrackList(tracksListTemp, true)
					Alert.alert(removeTrackLabel, 'Fjernet', [{ text: 'OK', onPress: () => console.log('OK Pressed') }])
				} else {
					Alert.alert(removeTrackLabel, 'Kunne ikke fjernes', [
						{ text: 'OK', onPress: () => console.log('OK Pressed') }
					])
				}
			})
			.catch(err => {
				Alert.alert(removeTrackLabel, 'Sporet kunne ikke fjernes', [
					{ text: 'OK', onPress: () => console.log('OK Pressed') }
				])
				console.log(err)
			})
			.finally(() => {
				setRemovePlaylistConfirmModal(false)
			})
	}

	return (
		<View>
			<MenuItem
				style={trackStyles.menuTextView}
				onPress={() => {
					setRemovePlaylistConfirmModal(true)
				}}
			>
				<Text style={trackStyles.menuTextView}>{removeTrackLabel}</Text>
			</MenuItem>
			{removePlaylistConfirmModal && (
				<View>
					<ConfirmationModal
						handleSubmit={removeFromPlaylist}
						modalVisible={removePlaylistConfirmModal}
						cancelCallback={cancelCallbackRemovePlaylist}
						description={'Er du sikker på, at du vil fjerne denne spilleliste?'}
					/>
				</View>
			)}
		</View>
	)
}
